<div class="duration-picker {className}">
    <div class="{element("duration-picker", 'container')} {statusClass}">
        <input {disabled} 
            isNumber={true}
            class={element("duration-picker", 'text')} 
            type='number'
            placeholder="0" 
            min="0"
			value={days}
			data-test={`${test_tag}-days`}
            on:change='updateSeconds(event, "day")' />
        <div class={element("duration-picker", 'label')}>Days</div>
    </div>

    <div class="{element("duration-picker", 'container')} {statusClass}">
        <input {disabled} 
            type='number'
            class={element("duration-picker", 'text')} 
            placeholder="0" 
            value={hours}
            min="0"
			max="23"
			data-test={`${test_tag}-hours`}
            on:change='updateSeconds(event, "hour")' />
        <div class={element("duration-picker", 'label')}>Hours</div>
    </div>

    <div class="{element("duration-picker", 'container')} {statusClass}">
        <input {disabled} 
            isNumber={true}
            class={element("duration-picker", 'text')} 
            type='number'
            placeholder="0"
            min="0"
            max="59"
			value={minutes}
			data-test={`${test_tag}-minutes`}
            on:change='updateSeconds(event, "minute")' />
        <div class={element("duration-picker", 'label')}>Minutes</div>
    </div>
</div>
{#if statusMessage}
<div class='status-message {statusClass}'>
    {statusMessage}
</div>
{/if}

<script>
	import { Colors, getColorAndClasses } from 'Services/colors';
	import { element } from 'Services/bem';

	export default {
		computed: {
			days: ({ seconds }) => {
				const secs = parseInt(seconds);
				let val = isNaN(secs) ? 0 : secs;
				return Math.floor(val / 24 / 60 / 60);
			},
			hours: ({ days, seconds }) => {
				const secs = parseInt(seconds);
				let val = isNaN(secs) ? 0 : secs;
				let remaining = val - days * 24 * 60 * 60;
				return Math.floor(remaining / 60 / 60);
			},
			minutes: ({ days, hours, seconds }) => {
				const secs = parseInt(seconds);
				let val = isNaN(secs) ? 0 : secs;
				let remaining = val - days * 24 * 60 * 60;
				remaining = remaining - hours * 60 * 60;
				return Math.floor(remaining / 60);
			},
		},
		data() {
			return {
				seconds: 0,
				className: '',
				disabled: false,
				statusClass: '',
				statusMessage: '',
				test_tag: ''
			};
		},
		methods: {
			updateSeconds(evt, type) {
				let { days, hours, minutes } = this.get();
				let seconds = 0;
				if (type === 'day') {
					days = evt.target.value;
				} else if (type === 'hour') {
					hours = evt.target.value;
				} else {
					minutes = evt.target.value;
				}

				seconds = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60;
				this.set({ seconds });
				this.fire('change', { seconds });
			},
		},
		helpers: {
			element,
		},
	};
</script>
<button
 data-test={test_tag}
 disabled={isDisabled}
 on:click="fire('click', event)"
 class='button {classes}'>
	{#if loading}
		<Spinner />
	{/if}
	{#if iconClass}
		<i class={iconClass}></i>
	{/if}
	<slot></slot>
</button>

<script>
    import { Colors, getColorAndClasses } from 'Services/colors';
    import { Spinner } from 'Shared';

    export default {
    	components: {
    		Spinner,
    	},
    	data() {
    		return {
				test_tag: '',
    			color: Colors.primary,
    			className: '',
    			iconClass: '',
    			disabled: false,
    			loading: false,
    		};
    	},
    	computed: {
    		classes: ({ color, className, isDisabled, loading }) => {
    			return getColorAndClasses(
    				color,
    				className,
    				isDisabled ? 'disabled' : '',
    				loading ? 'loading' : ''
    			);
    		},
    		isDisabled: ({ disabled, loading }) => {
    			return disabled || loading;
    		},
    	},
    };
</script>
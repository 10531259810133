{#if open}
<div data-test={test_tag} transition:fade class="notification {classes}">
	{#if !hideIcon}
		{#if color === 'error'}
			<i class='fas fa-times-circle'></i> 
		{:else}
			<i class='fas fa-info-circle'></i> 
		{/if}
	{/if}
    <slot></slot>
    {#if showClose}
	    <i on:click='close(event)' class='fas fa-times'></i> 
	{/if}
</div>
{:else}
<div style="display: none;">
	<slot></slot>
</div>
{/if}

<script>
    import { fade } from 'svelte-transitions';
    import { Colors, getColorAndClasses } from 'Services/colors';

    export default {
    	transitions: {
    		fade,
    	},
    	data() {
    		return {
    			color: Colors.primary,
    			className: '',
    			open: true,
				showClose: false,
				hideIcon: false,
				test_tag: ''
    		};
		},
    	computed: {
    		classes: ({ color, className }) => {
    			return getColorAndClasses(color, className);
    		},
    	},
    	methods: {
    		close(e) {
    			e.preventDefault();
    			this.fire('onRequestClose');
    			this.set({
    				open: false,
    			});
    		},
    	},
    };
</script>
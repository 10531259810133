<div class='banner {className}'>
<Notification 
    color='error' 
    open={$errorShow}
    test_tag='error-notification' 
    hideIcon={true} className='floating'>{$errorMessage}</Notification>

<BannerNotification 
    color={$notificationColor}
    show={$notificationShow} 
    message={$notificationMessage} />

<div class='toolbar-header'>
    <i class='fas fa-bars' data-test='open-toolbar' on:click='toggleToolbar()'></i>
    <div class='logo'>
        <div class='logo-lines'>
            <div class='logo-line-one'>
                {bannerLineOne}
            </div>
            {#if bannerLineTwo}
            <div class='logo-line-two'>
                {bannerLineTwo}
            </div>
            {/if}
        </div>
    </div>
</div>
    <div class='banner-inner {subtitle ? "banner-subtitle" : ""} {showPaddingLeft ? "" : "banner-inner--no-left-padding"}'>
    
    {#if breadcrumbs && breadcrumbs.length > 0}
        {#each breadcrumbs as breadcrumb}
            {#if breadcrumb.route}
                <div class='breadcrumb'>
                    <span class='title' on:click='route(breadcrumb)'>{breadcrumb.name}</span> 
                    <span class='caret'>&gt;</span>
                </div>
            {:else}
                <div class='breadcrumb read-only'>
                    <span class='title'>{breadcrumb.name}</span> 
                    <span class='caret'>&gt;</span>
                </div>
            {/if}
        {/each}
    {/if}
    {#if title}
    <span class='title'>{title}</span> 
    {/if}
    
    {#if subtitle}
        <span class='subtitle'>{subtitle}</span> 
    {/if}

    <slot></slot>

    <div class='alert'>
        {#if $progressShow} 
            <Spinner className='banner-spinner' />
        {/if}
    </div>

    </div>

    <Alerts on:click="navigateToDevicesPage(event)" />
    <Exceptions />
    
    <div class='profile-section'>
        <Profile />
    </div>
</div>

<script>
import Profile from './Profile.html';
import Alerts from './Alerts.html';
import Exceptions from './Exceptions.html';
import BannerNotification from './BannerNotification.html';
import { Notification, Spinner } from 'Shared';
export default {
    components: {
        Alerts,
        Exceptions,
        Notification,
        Profile,
        Spinner,
        BannerNotification
    },
    data() {
        return {
            className: '',
            breadcrumbs: [],
            showPaddingLeft: true,
        };
    },
    computed: {
        bannerLineOne: ({ $selectedCustomize }) => {
            if (
                $selectedCustomize &&
                $selectedCustomize.values &&
                $selectedCustomize.values.bannerLineOne
            ) {
                return $selectedCustomize.values.bannerLineOne;
            }
            return 'AQL Cloud';
        },
        bannerLineTwo: ({ $selectedCustomize }) => {
            if (
                $selectedCustomize &&
                $selectedCustomize.values &&
                $selectedCustomize.values.bannerLineTwo
            ) {
                return $selectedCustomize.values.bannerLineTwo;
            }
            return null;
        },
    },
    methods: {
        navigateToDevicesPage() {
            const { router } = this.get();
            router.route('/devices');
        },
        route(breadcrumb) {
            const { router } = this.get();
            router.route(breadcrumb.route);
        },
        toggleToolbar() {
            const { toolbarOpen } = this.store.get();
            this.store.toggleToolbar(!toolbarOpen);
        }
    },
};
</script>
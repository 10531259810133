<svelte:component 
    this={component} 
    on:valid="valid(event)" 
    {router}  />	
<StepPanel 
    this={component} 
    bind:currentStep 
    {steps} 
    {saving}
    on:cancel
    on:save />

<script>
	import { StepPanel } from 'Shared';

	export default {
		components: {
			StepPanel,
		},
		computed: {
			component: ({ steps, currentStep }) => {
				const step = currentStep || 0;
				return steps[step].component;
			},
		},
		data() {
			return {
				currentStep: 0,
				steps: [],
				saving: false,
			};
		},
		methods: {
			valid(isValid) {
				const { currentStep, steps } = this.get();
				steps[currentStep].valid = isValid;
				this.set({ steps });
			},
		},
	};
</script>
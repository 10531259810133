<Card className='{blockClass} {selectedClass(selected, value, blockClass)}'>
    <div class={contentClass}>
        <div class={radioButtonClass}>
            {#if isCheckbox}
            <Checkbox /> {:else}
            <input type="radio" {disabled} bind:group='selected' on:click="fire('select', { selected: value })" {value} /> {/if}
        </div>
        <div class={descriptionClass}>
            <h3 on:click="fire('select', { selected: value })">{title}</h3>
            <slot></slot>
        </div>
    </div>
</Card>


<script>
    import {
        Card,
        Button,
        Checkbox
    } from 'Shared';

    import * as BEM from 'Services/bem';


    const baseClass = "option";
    const bem = {
        blockClass: baseClass,
        contentClass: BEM.element(baseClass, "content"),
        radioButtonClass: BEM.element(baseClass, "radio-button"),
        descriptionClass: BEM.element(baseClass, "description")
    };

    const defaults = {
        selected: null,
        value: null,
        title: '',
        isCheckbox: false
    };

    export default {
        helpers: {
            selectedClass(actual, expected, elementName) {
                return actual === expected ?
                    BEM.modifier(elementName, 'selected') :
                    BEM.modifier(elementName, 'unselected');
            }
        },
        components: {
            Card,
            Checkbox
        },
        data() {
            return Object.assign({}, defaults, bem);
        }
    }
</script>
<Banner {router} {title} {subtitle}>
</Banner>
<SubBanner>
    <Tracker {currentStep} {steps} />
</SubBanner>

<script>
    import { Banner, SubBanner, Tracker } from 'Shared';

    export default {
    	components: {
    		Banner,
    		SubBanner,
    		Tracker,
    	},
    	data() {
    		return {
    			currentStep: 0,
    			steps: {},
    			title: '',
    			subtitle: '',
    		};
    	},
    };
</script>
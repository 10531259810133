<label class="switch {className}">
    <input data-test={test_tag} on:change="fire('change', event)" {disabled} {checked} type="checkbox" />
    <i class={labelPosition==='left' ? 'checkboxLeft' : 'checkboxRight'}></i>
    {#if !hideLabels}
    <div class="label {labelClass} {labelPositionClass} {checkedClass}">
        <slot></slot>
    </div>
    {/if}
</label>


<script>
    export default {
    	data() {
    		return {
    			className: '',
    			id: '',
    			checked: false,
    			disabled: false,
    			labelPosition: 'right',
    			labelClass: '',
    			hideLabels: false,
    		};
    	},
    	computed: {
    		labelPositionClass: ({ labelPosition }) => {
    			return labelPosition === 'left' ? 'labelLeft' : 'labelRight';
    		},
    		checkedClass: ({ checked }) => {
    			return checked ? 'labelActive' : '';
    		},
    	},
    };
</script>

<style>
    .label {
    	color: #354052;
    	font-weight: 500;
    	text-transform: none;
    	font-size: 16px;
    	float: right;
    	line-height: 25px;
    }

    .labelActive {
    	font-weight: 600;
    }

    .labelLeft {
    	margin-right: 10px;
    }

    .labelRight {
    	margin-left: 10px;
    }
</style>
<svelte:window on:click='close(event)' /> 
<div class={blockClass}>
    <div ref:selector 
		 data-test='group-selector'
		 class={classes(element(blockClass, 'dropdown'), openClass, disabled ? 'disabled' : '')} 
		 on:click='toggle(e)'>
        <div class={element(blockClass, 'name')}>
			{#if hasItems}
				{#if !hideLabel}
					<span class='groups-label'>Groups: </span>
				{/if}
				{#each displayItems as item}
					<Label test_tag={`${item.name} Label`} className='truncate group-label'>
						{item.name}
					</Label>
				{/each}
				{#if extra > 0}
					<Label className='group-label truncate'>
						+{extra} more
					</Label>
				{/if}
			{:else}
				<div class={element(blockClass, 'none-selected')}>
					No Groups Selected
				</div>
			{/if}
            <i class="fas fa-chevron-down {open ? 'open' : ''}"></i>
        </div>  
    </div>


    {#if open}
    <div ref:dropdown transition:fly class={element(blockClass, 'dropdown-panel')}>
        <div class={element(blockClass, 'dropdown-menu')}>
            {#each items as item}
            <div class={classes("dropdown-item", active(item.checked, true))} on:click='selectGroup(!item.checked, item.id)'>
                <div title={item.name} class='group-name'>
                {item.name}
                </div>
                <Checkbox test_tag={item.name} checked={item.checked} on:change='selectGroup(event.target.checked, item.id)' />
            </div>
            {/each}
        </div>
        <div class={element(blockClass, 'button-panel')}>
            <Button test_tag='group-submit' color='primary' on:click='save(event)'>Save</Button>
        </div>
    </div>

    {/if}
</div>


<script>
  import { element } from 'Services/bem';
  import { classes } from 'Services/classes';
  import { active } from 'Services/helpers';
  import { fly } from 'svelte-transitions';
  import { Button, Checkbox, Label } from 'Shared';

  function compare(groupOne, groupTwo) {
  	return groupOne.name.localeCompare(groupTwo.name);
  }

  export default {
  	transitions: { fly },
  	components: {
  		Button,
  		Checkbox,
  		Label,
  	},
  	computed: {
		extra: ({ displayItems, selectedGroups }) => {
			return selectedGroups.length - displayItems.length;
		},
		displayItems: ({ items, extra, groups, selectedGroups, max }) => {
			return items
				.filter(item => selectedGroups.findIndex(g => g.id === item.id) >= 0)
				.slice(0, max);
		},
  		items: ({ groups, selectedGroups, extra }) => {
  			return groups.sort(compare).map(group => {
  				return {
  					name: group.name,
  					id: group.id,
  					checked: selectedGroups.findIndex(g => g.id === group.id) >= 0,
  				};
  			});
		},
		hasItems: ({ selectedGroups }) => {
			return selectedGroups ? selectedGroups.length > 0 : false;
		}
  	},
  	data() {
  		return {
  			blockClass: 'group-selector',
  			promise: null,
  			items: [],
  			groups: [],
			selectedGroups: [],
			hideLabel: false,
			max: 2
  		};
	},
  	helpers: {
  		active,
  		classes,
  		element
  	},
  	methods: {
  		close(event) {
  			if (this.refs.selector && this.refs.selector.contains(event.target)) {
  				event.preventDefault();
  				return;
  			}

  			if (this.refs.dropdown && this.refs.dropdown.contains(event.target)) {
  				return;
  			}

  			this.set({
  				open: false,
  			});
  		},
  		toggle() {
  			const { open, disabled } = this.get();
  			if (!disabled) {
  				this.set({ open: !open });
  			}
  		},
  		removeGroup(checked, id) {
  			const { items } = this.get();
  			const index = items.findIndex(i => i.id === id);
  			if (index >= 0) {
  				items.splice(index, 1);

  				const payload = {
  					ids: items.filter(item => item.checked).map(item => item.id),
			};

  				this.fire('groupselect', payload);
  				this.set({ items });
  			}
  		},
  		selectGroup(checked, id) {
  			const { items } = this.get();
  			const item = items.find(i => i.id === id);
  			item.checked = checked;

  			this.set({
  				items,
  			});
  		},
  		save(event) {
  			event.preventDefault();

  			const { items } = this.get();

  			const payload = {
  				ids: items.filter(item => item.checked).map(item => item.id),
  			};

  			this.set({ open: false });
  			this.fire('groupselect', payload);
  		},
  	},
  };
</script>
<div class='exception-item'>
    <div class='exception-item-info'>
        {#if count > 1}
        <div class='exception-text'>{text}</div>
        <div class='exception-date space-top'>Count: {count}</div>
        <div class='exception-date'>Latest: {formatDate(updatedAt)} {formatTime(updatedAt)}</div>
        <div class='exception-date space-bottom'>Began: {formatDate(createdAt)} {formatTime(createdAt)}</div>
        <div class='exception-device'>{deviceName}</div>
        <div class='exception-serial-number'>{deviceSerialNumber}</div>
        {:else}
        <div class='exception-text'>{text}</div>
        <div class='exception-date space'>At: {formatDate(updatedAt)} {formatTime(updatedAt)}</div>
        <div class='exception-device'>{deviceName}</div>
        <div class='exception-serial-number'>{deviceSerialNumber}</div>
        {/if}
    </div>
    <div class='exception-item-actions'>
        {#if $fullAccess}
        <div class='exception-item-acknowledge {disable ? "disable" : ""}'>
            <span class="fa-stack icon-group" on:click='onAck("ack", { id, deviceId })'>
                <i class="fas fa-circle fa-stack-1x"></i>
                <i class='fas fa-stack-1x fa-check'></i>
            </span>
        </div>
        <div class='exception-item-block {disable ? "disable" : ""}'>
            <span class="fa-stack icon-group" on:click='onAck("block", { id, deviceId })'>
                <i class="fas fa-circle fa-stack-1x"></i>
                <i class='fas fa-stack-1x fa-ban'></i>
            </span>
        </div>
        {/if}
    </div>
</div>


<script>
import { formatDate, formatTime } from 'Services/formatters';
export default {
    helpers: {
        formatDate,
        formatTime
    },
    computed: {
        device: ({ $devices, deviceId }) => {
            return $devices.find(device => device.id === deviceId);
        },
        deviceName: ({ device }) => {
            return device ? device.loggerName : '';
        },
        deviceSerialNumber: ({ device }) => {
            return device ? device.serialNumber : '';
        }
    },
    data() {
        return {
            count: 1,
            disable: false,
            createdAt: new Date(),
            updatedAt: new Date(),
            deviceId: null,
            id: null,
            text: ''
        }
    },
    methods: {
        onAck(type, payload) {
            const { disable } = this.get();
            if (!disable) {
                this.fire(type, payload);
            }
        }
    }
}
</script>
<Dropdown
	on:select='select(event)'
	{selected}
	{disabled}
	className="range-dropdown action-dropdown {className}"
	test_tag="range-dropdown"
	{items}
	optionalDisplay={showOptionalDisplay ? customDate : ""}
/>

{#if showCustomRangeModal}
<Modal
	ref:customRangeModal
	{trapFocus}
	{initialFocusElement}
	on:result="setCustomRange(event)"
>
	<CustomRangeModal
		{startDate}
		{endDate}
		on:dismiss="refs.customRangeModal.dismiss(event)"
		on:cancel="refs.customRangeModal.dismiss(event)"
	>
	</CustomRangeModal>
</Modal>
{/if}
<script>
	import { ViewRangeType } from '@shanewwarren/aqlcloud-shared-types';
	import Dropdown from './Dropdown';
	import CustomRangeModal from './CustomRangeModal';
	import Modal from './Modal/Modal';
	import { formatDate, formatTime } from 'Services/formatters';
	import * as subDays from 'date-fns/sub_days';
	import * as startOfDay from 'date-fns/start_of_day';

	const items = [
		{ key: 'Past Hour', value: ViewRangeType.Hour },
		{ key: 'Past Day', value: ViewRangeType.Day },
		{ key: 'Past Week', value: ViewRangeType.Week },
		{ key: 'Past Month', value: ViewRangeType.Month },
		{ key: 'Custom Range', value: ViewRangeType.Custom },
	];

	export default {
		components: {
			Dropdown,
			CustomRangeModal,
			Modal
		},
		computed: {
			showOptionalDisplay: ({ selected }) => selected === ViewRangeType.Custom,
			customDate: ({ startDate, endDate }) => {
				return `${formatDate(startDate)} ${formatTime(startDate)} - ${formatDate(endDate)} ${formatTime(endDate)}`;
			}
		},
		data() {
			return {
				blockClass: 'dashboard-toolbar',
				rangeType: '',
				items,
				selected: ViewRangeType.Day,
				showCustomRangeModal: false,
				className: '',
				startDate: undefined,
				endDate: undefined,
			};
		},
		methods: {
			select(event) {
				const key = event;
				if (key === ViewRangeType.Custom) {
					let { startDate, endDate } = this.get();
					startDate = startDate || startOfDay.default(subDays.default(new Date, 1));
					endDate = endDate || new Date();
					this.set({ showCustomRangeModal: true, startDate, endDate });
				} else {
					this.fire('select', key);
				}
			},
			setCustomRange(payload) {
				this.set({ showCustomRangeModal: false });
    			if (!payload) {
    				return;
				}
				this.fire('range', payload);
    		}
		},
	};
</script>

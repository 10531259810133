<div class='checkbox'>
    {#if labelPosition === 'left'}
    <label for={id} class="label {labelClass} {labelPositionClass} {checkedClass} {disabledClass} {hideLabelsClass}">
        <slot></slot>
    </label> 
    {/if}
    <input data-checked={checked} data-test={test_tag} on:change='fire("change", event)' class="test {className}" type="checkbox" {id} bind:checked {disabled} /> 
    {#if labelPosition === 'right'}
    <label for={id} class="label {labelClass} {labelPositionClass} {checkedClass} {disabledClass} {hideLabelsClass}">
        <slot></slot>
    </label> 
    {/if}
</div>
<script>
    export default {
    	data() {
    		return {
    			id: '',
    			className: '',
    			checked: false,
    			disabled: false,
    			labelPosition: 'right',
    			labelClass: '',
    			labelText: '',
				hideLabels: false,
				test_tag: ''
    		};
    	},
    	computed: {
    		labelPositionClass: ({ labelPosition }) => {
    			return labelPosition === 'left' ? 'labelLeft' : 'labelRight';
    		},
    		checkedClass: ({ checked }) => {
    			return checked ? 'labelActive' : '';
    		},
    		disabledClass: ({ disabled }) => {
    			return disabled ? 'disabled' : '';
    		},
    		hideLabelsClass: ({ hideLabels }) => {
    			return hideLabels ? 'hideLabels' : '';
    		},
    	},
    };
</script>

<style>
    .label {
    	color: #354052;
    	font-weight: 500;
    	text-transform: none;
    	font-size: 16px;
    }

    .labelActive {
    	font-weight: 600;
    }

    .labelLeft {
    	margin-right: 0px;
    }

    .labelRight {
    	margin-left: 0;
    }

    .checkboxLeft {
    	float: right;
    }

    .checkboxRight {
    	float: left;
    }

    .hideLabels {
    	display: none;
    }
</style>
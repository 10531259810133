<div class={className}>
    {#if label}
        <label class='text-input-label {labelClassName}'>{label}</label> 
    {/if}
    <div class='radio-button-group {orientation}'>
        {#each items as item, index}
        <div class='radio-button'>
            <input data-test={`${testTag}-${item.key}`} type="radio" id="{id}_{index}" {disabled} bind:group='selected' value={item.value}/>
            <label for="{id}_{index}" class="label {labelClasses}">{item.key}</label>
        </div>
        {/each}
    </div>
</div>

<script>
    import { getClasses } from 'Services/colors';
    export default {
        data() {
            return {
                id: '',
                className: '',
                items: [],
                disabled: false,
                selected: null,
                labelPosition: 'right',
                labelClass: '',
                labelClassName: '',
                label: '',
                orientation: 'vertical',
                test_tag: ''
            }
        },
        onupdate({ changed, current, previous }) {
            if (changed.selected) {
                this.fire('change', current.selected);
            }
        },
        computed: {
            testTag: ({ test_tag }) => {
                if (test_tag) {
                    return `radio-${test_tag}`;
                }
                return 'radio';
            },
            labelPositionClass: ({ labelPosition }) => {
                return labelPosition === 'left' ? 'labelLeft' : 'labelRight';
            },
            disabledClass: ({ disabled }) => {
                return disabled ? 'disabled' : '';
            },
            labelClasses: ({ labelClass, labelPositionClass, disabledClass }) => {
                return getClasses(labelClass, labelPositionClass, disabledClass);
            }
        }
    }

</script>

<style>
    .label {
        color: #fff;
        font-weight: 500;
        text-transform: none;
        font-size: 16px;
    }

    .labelActive {
        font-weight: 600;
    }

    .labelLeft {
        margin-right: 0px;
    }

    .labelRight {
        margin-left: 0;
    }

    .checkboxLeft {
        float: right;
    }

    .checkboxRight {
        float: left;
    }
</style>
<svelte:window on:click='close(event)' /> {#if label}
<label class='dropdown-label'>{label}</label> {/if}
<div ref:dropdown class="action-dropdown {disabled ? 'disabled' : ''} {className}">
    <a data-test={test_tag} on:click='toggle(event)' class="dropdown-toggle {placeholderClass}" href="#" {id} role="button" aria-haspopup="true" aria-expanded={open}>
        { selected ? selected.key : placeholder }
        <i class="fas fa-chevron-down {open ? 'open' : ''}"></i>
    </a> {#if open}
    <div transition:fade='{duration: 200}' ref:dropdownPanel
        class="dropdown-menu {dropdownClassName}" style="top: {top}px;" aria-labelledby="dropdownMenuLink">
        {#each items as item, index} 
            {#if selected && item.value === selected.value}
            <a title={item.key} data-test={item.value} on:click='select(event)' class="dropdown-item selected {item.disabled === true ? 'disabled' : ''}" href="#" datatag-index={index}>
                {#if item.icon}
                    <i class={item.icon} />
                {/if}
                {item.key}
            </a> 
            {:else}
            <a title={item.key} data-test={item.value} on:click='select(event)' class="dropdown-item {item.disabled === true ? 'disabled' : ''}" href="#" datatag-index={index}>
                {#if item.icon}
                    <i class={item.icon} />
                {/if}
                {item.key}
            </a> 
            {/if} 
        {/each}
    </div>
    {/if}
</div>

<script>
    import { fade } from 'svelte-transitions';

    export default {
        transitions: { fade },
        data() {
            return {
                className: '',
                dropdownClassName: '',
                iconClass: '',
                iconPosition: 'right',
                id: '',
                disabled: false,
                placeholder: '',
                selected: null,
                items: [],
                open: false,
                label: '',
                isFixed: false,
                top: 'auto',
                test_tag: '',
                autoPlaceDropdown: false
            }
        },
        computed: {
            placeholderClass: ({ selected }) => {
                return selected ? '' : 'placeholder';
            }
        },
        onupdate({ changed, current, previous }) {
            if (changed.open && current.open && current.autoPlaceDropdown) {
                const rect = this.refs.dropdown.getBoundingClientRect();
                const scrollY = window.scrollY;
                const top = rect.top + rect.height + scrollY;
                this.refs.dropdownPanel.setAttribute("style", `top: ${top}px`, '!important');
            }
        },
        methods: {
            close(event) {
                if (this.refs.dropdown.contains(event.target)) {
                    event.preventDefault();
                    return;
                }

                this.set({
                    open: false
                });
            },
            toggle(event) {
                let { disabled, open } = this.get();
                if (disabled) {
                    event.preventDefault();
                    return;
                }
                this.set({ open: !open });
            },
            select(event) {
                const { disabled, items } = this.get();
                if (disabled) {
                    event.preventDefault();
                    return;
                }

                const index = parseInt(event.target.getAttribute('datatag-index'));
                if (items.length > 0 && index < items.length) {
                    const item = items[index];
                    if (item.disabled === true) {
                        return;
                    }
                    this.fire('select', item);
                }

                this.toggle();
            }
        }
    }
</script>
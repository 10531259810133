<ul class={blockClass}>
    {#each items as item}
        <li on:click='set({ selectedKey: item.key })' 
            class={active(selectedKey, item.key)}
            data-test={`tab-${item.key}`}>
            <a>{item.displayName}</a>
        </li>
    {/each}
</ul>


<script>
    import { active } from 'Services/helpers';

    export default {
        data() {
            return {
                items: [],
                selectedKey: null
            };
        },
        helpers: {
            active,
            blockClass: 'tabs'
        },
        methods: {}
    }
</script>
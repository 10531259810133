<Card className='modal-card'>
    <div class='confirm-dialog'>
        <form>
            <fieldset>
                <div class='legend'>
                    <legend>
                        {message}
                        <div class='secondary'>{secondaryMessage}</div>
                    </legend>
                </div>
            </fieldset>
            <div class='button-panel'>
                <Button on:click='submit(event)' color='primary' className="{buttonConfirmClass} ok">{buttonConfirmText}</Button>
                {#if !hideCancel}
                <Button on:click='cancel(event)' className="cancel" color="normal">{buttonCancelText}</Button>
                {/if}
            </div>
        </form>

    </div>
</Card>

<script>
import { Card, Button } from 'Shared';

export default {
    components: { Card, Button },
    data() {
        return {
            message: '',
            secondaryMessage: '',
            buttonConfirmText: 'Ok',
            buttonConfirmClass: '',
            buttonCancelText: 'Cancel',
            hideCancel: false
        };
    },
    methods: {
        submit(e) {
            e.preventDefault();
            this.fire('dismiss', true);
        },
        cancel(e) {
            e.preventDefault();
            this.fire('cancel');
        },
    }
}
</script>
<svelte:window on:click='close(event)' /> 
<div  ref:selector class='exceptions-section {openClass}' on:click='toggle(event)'>  
    <span title="{count} reported device {pluralize('exception', count)}." class="fa-stack exception-icon-group">
        <i class="fas fa-square fa-stack-1x icon-square {exceptionClass}"></i>
        <i class='fas fa-stack-1x  fa-exclamation-square {exceptionClass} icon-primary'></i>
    </span>
    {#if count > 0}
        <div class='count-container {countClass}'>
            <div class='count'>{count}</div>
        </div>
    {/if}

    {#if open}
    <div ref:dropdown transition:fly class='dropdown-menu'>
        {#each items as e}
            <ExceptionItem
                disable={disable}
                count={e.count}
                createdAt={e.createdAt}
                updatedAt={e.updatedAt}
                deviceId={e.deviceId}
                id={e.id}
                text={e.text}
                on:ack='onAck(event)'
                on:block='onBlock(event)' />
        {/each}
    </div>
    {/if}
</div>


<script>
import { pluralize } from 'Services/helpers';
import { fly } from 'svelte-transitions';
import ExceptionItem from './ExceptionItem.html';
import { dateCompare } from 'Services/table';
export default {
    components: {
        ExceptionItem
    },
    helpers: {
        pluralize
    },
    transitions: { fly },
    computed: {
        count: ({ $exceptions }) => {
            return $exceptions.length;
        },
        openClass: ({ open }) => {
            return open ? 'open' : '';
        },
        exceptionClass: ({ count }) => {
            return count === 0 ? '' : 'has-notify-items';
        },
        items: ({ $exceptions }) => {
            return $exceptions.sort((a,b) => dateCompare(a, b, 'updatedAt'))
        },
        countClass: ({ count }) => {
            if (count / 10 < 1) {
                return 'single';
            } else if (count / 100 < 1) {
                return 'double';
            } else if (count / 1000 < 1) {
                return 'triple';
            } else {
                return 'quadruple'
            }
        }
    },
    data() {
        return {
            open: false,
            disable: false,
            promise: null
        }
    },
    oncreate() {
        const promise = this.store.getExceptions();
        this.set({ promise });
    },
    methods: {
        close(event) {
            if (this.refs.selector.contains(event.target)) {
                event.preventDefault();
                return;
            }

            this.set({
                open: false,
            });
        },
        toggle(event) {
            const { open, count } = this.get();

  			if (this.refs.dropdown && this.refs.dropdown.contains(event.target)) {
  				return;
  			}

            if (count > 0) {
                this.set({
                    open: !open,
                });
            }
        },
        onAck(event) {
            const { id, deviceId } = event;
            this.set({ disable: true });
            this.store.showProgress();
            this.store.acknowledgeException(deviceId, id).then(() => {
                this.store.showNotification('Exception acknowledged.');
                this.set({ disable: false });
            }).catch((err) => {
                this.store.showError(err.message);
                this.set({ disable: false });
            });
        },
        onBlock(event) {
            const { id, deviceId } = event;
            this.set({ disable: true });
            this.store.showProgress();
            this.store.blockException(deviceId, id).then(() => {
                this.store.showNotification('Exception blocked.');
                this.set({ disable: false });
            }).catch((err) => {
                this.store.showError(err.message);
                this.set({ disable: false });
            });
        }
    }
}
</script>
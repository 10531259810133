<ul class={blockClass}>
    {#each items as item}
        <li on:click='onTabChange(event, item.key)' 
        class={active(selectedKey, item.key)}
        data-test={`tab-${item.key}`}>
            <a>{item.displayName}</a>
        </li>
    {/each}
</ul>


<script>
    import { active } from 'Services/helpers';

    export default {

        data() {
            return {
                items: [],
                selectedKey: null
            };
        },
        helpers: {
            active,
            blockClass: 'tab-list'
        },
        methods: {
            onTabChange(evt, key) {
                evt.preventDefault();
                this.fire('change', { selectedKey: key });
                this.set({ selectedKey: key });
            }
        }
    }
</script>
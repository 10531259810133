<div class="{containerClassName}">
    {#if label}
    <label class='text-input-label'>{label}</label> {/if}
    <div class='text-input {statusClass}'>
        {#if iconInside && iconPosition === 'left'}
        <i class="icon-left {calculatedIconClass}"></i> 
        {/if}
        {#if showPassword}
        <input autocomplete="on" value={value} data-test={test_tag} class={classes} type='text' {disabled} {placeholder} bind:value="value" /> 
        {:else}
        <input autocomplete="on" value={value} data-test={test_tag} class={classes} type='password' {disabled} {placeholder} bind:value="value" /> 
        {/if}
        {#if iconInside && iconPosition === 'right'}
        <i on:click="toggleShowPassword(event)" class="icon-right {calculatedIconClass}"></i> 
        {/if}
    </div>
    {#if statusMessage}
    <div class='status-message {statusClass}'>
        {#if iconOutside}
        <i class="{calculatedIconClass} {statusClass}"></i> {/if} {statusMessage}
    </div>
    {/if}
</div>

<script>
    import {
        getClasses
    } from 'Services/colors';

    export default {
        data() {
            return {
                test_tag: '',
                containerClassName: '',
                className: '',
                iconClass: '',
                iconPosition: 'right',
                id: '',
                disabled: false,
                value: '',
                placeholder: '',
                label: '',
                statusClass: '',
                statusMessage: '',
                showPassword: false,
            }
        },
        computed: {
            classes: ({ className, iconClass, iconPosition }) => {
                if (iconClass) {
                    return getClasses(className, 'icon-text', iconPosition === 'left' ? 'icon-text-left' : 'icon-text-right');
                }

                return className;
            },
            calculatedIconClass: ({ statusClass, showPassword }) => {
                if (showPassword) {
                    return 'fas fa-eye';
                } else if (!showPassword) {
                    return 'fas fa-eye-slash';
                }

                if (statusClass === 'success') {
                    return 'fas fa-check';
                } else if (statusClass === 'error') {
                    // return 'fas fa-times';
                }

                return '';
            },
            iconInside: ({ calculatedIconClass, statusMessage }) => {
                return (calculatedIconClass && !statusMessage)
            },
            iconOutside: ({ calculatedIconClass, statusMessage }) => {
                return (calculatedIconClass && statusMessage)
            },
        },
        methods: {
            toggleShowPassword(event) {
                event.preventDefault();
                const { showPassword } = this.get();
                this.set({ showPassword: !showPassword })
            }
        }
    }
</script>
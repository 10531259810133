<div class='card {className}'>
    <slot></slot>
</div>

<script>
    export default {
        data() {
            return {
                className: ''
            }
        }
    }
</script>
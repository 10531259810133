<svelte:window on:click='close(event)' /> {#if label}
<label class='dropdown-label'>{label}</label> {/if}
<div data-test={test_tag} data-selected={selectedKey} ref:dropdown class="dropdown {disabled ? 'disabled' : ''} {className}">
    <div on:click='toggle(event)' class="dropdown-toggle {placeholderClass}" {id} role="button" aria-haspopup="true" aria-expanded={open}>
        <div class="{itemClass} margin-r-1">{ optionalDisplay || selectedKey }</div>
        <i class="fas fa-chevron-down {open ? 'open' : ''}"></i>
    </div> {#if open}
    <div  class="dropdown-menu {dropdownClassName}" aria-labelledby="dropdownMenuLink">
        {#each items as item, index} 
			{#if selected && item.value === selected.value}
				<a title={item.key} on:click='select(event)' 
				class="{itemClass} dropdown-item selected" 
				href="#" 
				datatag-index={index}
				data-test={item.key}>{item.key}</a> 
			{:else}
				<a title={item.key} on:click='select(event)' 
				class="{itemClass} dropdown-item" 
				href="#" 
				datatag-index={index}
				data-test={item.key}>{item.key}</a> 
			{/if} 
		{/each}
    </div>
    {/if}
</div>

<script>
    export default {
    	data() {
    		return {
    			className: '',
    			dropdownClassName: '',
    			iconClass: '',
    			iconPosition: 'right',
    			id: '',
    			disabled: false,
    			placeholder: '',
    			selected: null,
    			items: [],
    			open: false,
    			label: '',
				itemClass: '',
				test_tag: '',
				optionalDisplay: ''
    		};
    	},
    	computed: {
    		placeholderClass: ({ selected }) => {
    			return selected ? '' : 'placeholder';
    		},
    		selectedKey: ({ items, placeholder, selected }) => {
    			if (!selected && selected !== 0) {
    				return placeholder;
    			}
    			const found = items.find(item => item.value === selected);
    			return found ? found.key : placeholder;
    		},
    	},
    	methods: {
    		close(event) {
    			if (this.refs.dropdown.contains(event.target)) {
    				event.preventDefault();
    				return;
    			}

    			this.set({
    				open: false,
    			});
    		},
    		toggle(event) {
    			const { open, disabled } = this.get();
    			if (disabled) {
    				event.preventDefault();
    				return;
    			}

    			this.set({
    				open: !open,
    			});
    		},
    		select(event) {
    			const { disabled, items } = this.get();
    			if (disabled) {
    				event.preventDefault();
    				return;
    			}

    			const index = parseInt(event.target.getAttribute('datatag-index'));
    			if (items.length > 0 && index < items.length) {
					const item = items[index];
					this.fire('select', item.value);
    				// this.set({ selected: item.value });
    			}

    			this.toggle();
    		},
    	},
    };
</script>
<label style={showCursor ? 'cursor: pointer;' : ''} data-test={test_tag} on:click class={classes}>
    <slot></slot>
</label>

<script>
    import {
        Colors,
        getColorAndClasses
    } from 'Services/colors';

    export default {
        data() {
            return {
                color: Colors.primary,
                className: '',
                test_tag: '',
                showCursor: false
            }
        },
        computed: {
            classes: ({ color, className }) => {
                return getColorAndClasses(color, className)
            }
        }
    }
</script>
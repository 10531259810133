<div class="{containerClassName}">
    {#if label}
        <label class='text-input-label {labelClassName}'>{label}</label> 
    {/if}
    <div class='text-input {statusClass}'>
        {#if iconInside && iconPosition === 'left'}
        <i class="icon-left {calculatedIconClass}"></i> 
        {/if} 
        
        {#if isTextArea}
        <textarea data-test={test_tag} class={classes} type="text" {disabled} {placeholder} bind:value on:keyup {autofocus} /> 
        {:elseif isNumber}
        <input data-test={test_tag} on:change on:input class={classes} type="number" :step {disabled} {placeholder} bind:value on:keyup {autofocus} /> 
        {:else}
        <input data-test={test_tag} class={classes} type="text" {disabled} {placeholder} bind:value on:keyup {autofocus} /> 
        {/if} 
        
        {#if iconInside && iconPosition === 'right'}
        <i class="icon-right {calculatedIconClass}"></i> 
        {/if}
    </div>
    {#if statusMessage}
    <div data-test={`${test_tag}-error`} class='status-message {statusClass}'>
        {#if iconOutside}
        <i class="{calculatedIconClass} {statusClass}"></i> 
        {/if}
        {statusMessage}
    </div>
    {/if}
</div>

<script>
    import { getClasses } from 'Services/colors';
    export default {
    	data() {
    		return {
				test_tag: '',
    			autofocus: false,
    			containerClassName: '',
    			labelClassName: '',
    			className: '',
    			iconClass: '',
    			iconPosition: 'right',
    			id: '',
    			disabled: false,
    			value: '',
    			placeholder: '',
    			label: '',
    			statusClass: '',
    			statusMessage: '',
    			isTextArea: false,
    			isNumber: false,
    		};
    	},
    	computed: {
    		classes: ({ className, iconClass, iconPosition }) => {
    			if (iconClass) {
    				return getClasses(
    					className,
    					'icon-text',
    					iconPosition === 'left' ? 'icon-text-left' : 'icon-text-right'
    				);
    			}

    			return className;
    		},
    		calculatedIconClass: ({ iconClass, statusClass }) => {
    			if (iconClass) {
    				return iconClass;
    			}

    			if (statusClass === 'success') {
    				return 'fas fa-check';
    			} else if (statusClass === 'error') {
    				//return 'fas fa-times';
    			}

    			return '';
    		},
    		iconInside: ({ calculatedIconClass, statusMessage }) => {
    			return calculatedIconClass && !statusMessage;
    		},
    		iconOutside: ({ calculatedIconClass, statusMessage }) => {
    			return calculatedIconClass && statusMessage;
    		},
    	},
    	methods: {},
    };
</script>
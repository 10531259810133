<div class={classes(blockClass, className, withBackgroundClass, fullHeightClass)}>
    <div class={element(blockClass, 'inner')} />
</div>


<script>
    import { element } from 'Services/bem';
    import { classes } from 'Services/classes';
    export default {
		computed: {
			withBackgroundClass: ({ withBackground }) => withBackground ? 'with-background': '',
			fullHeightClass: ({ fullHeight }) => fullHeight ? 'full': ''
		},
    	data() {
    		return {
    			blockClass: 'spinner',
    			className: null,
				callback: null,  
				withBackground: false,
				fullHeight: false,
    		};
    	},
    	helpers: {
    		classes,
    		element,
    	},
    };
</script>